import React from 'react';
import PropTypes from 'prop-types';

import { package as packagePropType } from 'lib/CustomPropTypes';
import RecipeDetails from 'components/Recipe/RecipeDetails';
import { Author } from '../Author';
import { OneByOne } from '../OneByOne';
import { OneByOneProduct } from '../OneByOneProduct';
import { TwoByOne } from '../TwoByOne';
import { TwoByThree } from '../TwoByThree';
import { ShopListByOne } from '../ShopListByOne';


const CARD_MAP = {
  default: TwoByOne,
  twoOneSmall: TwoByOne,
  twoOneLarge: TwoByOne,
  twoOneLargeItems: TwoByOne,
  oneOne: OneByOne,
  shopListByOneProduct: ShopListByOne,
  oneOneProduct: OneByOneProduct,
  twoThreePortrait: TwoByThree,
  author: Author,

};

const CARD_VARIANT = {
  twoOneLarge: 'large',
  twoOneLargeItems: 'large',
};

const ADDITIONAL_SELLER_CARD_MAP = {
  default: OneByOne,
  oneOne: OneByOne,
  shopListByOneProduct: ShopListByOne,
};

function CardSelector({
  content,
  shouldShowAdditionalSeller,
  showItemDescriptions,
  item,
  totalNumberOfCards,
  indexOffset,
  containerClasses,
  trackLink,
  onAddProduct,
  setMbtBaconClickTracking,
}) {
  const {
    type = null,
    subType,
    metadata,
    cardType = undefined,
  } = content;
  // AUTHOR selection logic

  const author = item?.authors?.[0];
  const authorName = author?.person?.name;
  const authorPhotoUrl = author?.person?.primaryImage?.url?.primary;

  if (cardType === 'author' && !authorName && !authorPhotoUrl) {
    return null;
  }
  //* *** */

  const { title } = metadata || {};

  const {
    metadata: cardMetadata = {},
    item: card,
  } = item;
  //* *** */
  // CARD selection logic
  const cardMap = shouldShowAdditionalSeller ? ADDITIONAL_SELLER_CARD_MAP : CARD_MAP;
  const Card = cardMap[subType] ?? (cardMap[cardType] ?? cardMap.default);
  const variant = CARD_VARIANT[subType];
  //* *** */

  return (
    <div
      className={containerClasses}
      data-test-id="card"
      data-testid="baconCardWrapper"
    >
      <Card
        totalNumberOfCards={totalNumberOfCards}
        indexOffset={indexOffset}
        card={item}
        title={title}
        openInNewTab={cardMetadata.openInNewTab}
        pkgType={type}
        showEyebrow={metadata?.showEyebrow}
        showTimestamp={metadata?.showTimestamp}
        trackBacon={setMbtBaconClickTracking}
        trackLink={trackLink}
        shouldShowAdditionalSeller={shouldShowAdditionalSeller}
        onAddProduct={onAddProduct}
        shouldShowDescription={showItemDescriptions}
        variant={variant}
      />
      <RecipeDetails
        type={item?.type}
        cookTime={card?.cookTime || item?.cookTime}
        prepTime={card?.prepTime || item?.prepTime}
      />
    </div>
  );
}

CardSelector.propTypes = {
  content: packagePropType.isRequired,
  shouldShowAdditionalSeller: PropTypes.bool.isRequired,
  showItemDescriptions: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  totalNumberOfCards: PropTypes.number.isRequired,
  indexOffset: PropTypes.number.isRequired,
  containerClasses: PropTypes.string.isRequired,
  trackLink: PropTypes.func,
  onAddProduct: PropTypes.func,
  setMbtBaconClickTracking: PropTypes.func.isRequired,
};
CardSelector.defaultProps = {
  trackLink: null,
  onAddProduct: null,
};


export { CardSelector };
