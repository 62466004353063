import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const alignments = ['right', 'center'];
const displayTypes = ['expand-down', 'form-button', 'no-arrow'];

/**
 * Text content to be rendered in the button
  * @param {string} [title] - custom text for button, to replace default
  * @param {string} [type] - used to add default styles for specific uses
  * @returns {React.ReactElement}
 */
const ButtonText = ({ title, type }) => {
  const { t } = useTranslation();
  const iconType = type === 'expand-down' ? 'icon-chevron-light-down' : 'icon-chevron-right';

  return (
    <>
      {title || t('See More')}
      {type !== 'no-arrow' ? (
        <span
          className={classNames(styles.caret, 'icon', iconType)}
          aria-hidden="true"
          focusable="false"
          data-testid="caret"
          data-test="caret"
        />
      ) : null}
    </>
  );
};

ButtonText.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
};

ButtonText.defaultProps = {
  title: null,
  type: null,
};

/**
 * Link or button with a tilt animation on hover
  * @param {string} [title] - custom text for button, to replace default
  * @param {string} [url] - the URL the user will be redirected to on click
  * @param {string} [target] - target attibute for a link
  * @param {string} [alignment] - the visual alignment of the button element
  * @param {boolean} [isFullWidth] - sets the button width
  * @param {string} [id] - the ID to assign a button element
  * @param {function} [onClick] - click handler for button events
  * @param {boolean} [disabled] - disabled property for a button
  * @param {string} [icid] - name for analytics tracking
  * @param {string} [tiltClasses] - additional classes to be added to the clickable element
  * @param {string} [displayType] - used to add default styles for specific uses
  * @returns {React.ReactElement}
 */
const ButtonTilt = ({
  url,
  title,
  alignment,
  isFullWidth,
  id,
  onClick,
  target,
  disabled,
  icid,
  tiltClasses,
  displayType,
}) => {
  const classString = classNames(
    styles.button,
    styles[alignment],
    tiltClasses,
    {
      [styles.fullWidth]: isFullWidth,
      [styles.formButton]: displayType === 'form-button',
      [styles.expandDown]: displayType === 'expand-down',
    },
  );

  const Div = (props) => <div className={styles[alignment]} data-testid="div-wrap" {...props} />;
  const Wrap = alignment ? Div : Fragment;

  if (onClick) {
    return (
      <Wrap>
        <button
          className={classString}
          disabled={disabled}
          id={id}
          onClick={onClick}
          type="button"
          data-testid="button-tilt"
        >
          <ButtonText title={title} type={displayType} />
        </button>
      </Wrap>
    );
  }

  return url ? (
    <Wrap>
      <Link
        className={classString}
        role="button"
        href={url}
        target={target}
        icid={icid}
        data-testid="link-tilt"
      >
        <ButtonText title={title} type={displayType} />
      </Link>
    </Wrap>
  ) : null;
};

ButtonTilt.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  alignment: PropTypes.oneOf(alignments),
  isFullWidth: PropTypes.bool,
  tiltClasses: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  icid: PropTypes.string,
  displayType: PropTypes.oneOf(displayTypes),
};

ButtonTilt.defaultProps = {
  title: null,
  url: null,
  target: '_self',
  alignment: null,
  isFullWidth: false,
  tiltClasses: null,
  disabled: false,
  id: null,
  onClick: null,
  icid: null,
  displayType: null,
};

export { ButtonTilt };
