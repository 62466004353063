import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { detect } from 'detect-browser';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import {
  NEWS_NOW_DEFAULT_SLATE,
  /* eslint-disable-next-line no-unused-vars */
  STREAM_KEYS,
  STREAM_TO_SLATE_MAP,
} from 'lib/playmaker';
import styles from './styles.module.scss';

/**
 * OmegaSlate: Shows a slate when OmegaPlayer is loading or has an error.
 *
 * @typedef {object} OmegaSlateProps
 * @property {boolean} coreVideoReady Flag to indicate if OmegaPlayer is ready.
 * @property {boolean} hasError Flag to indicate if an error occurred in OmegaPlayer.
 * @property {STREAM_KEYS} stream STREAM_KEY value to determine correct slate image.
 *
 * @param {OmegaSlateProps} props
 *
 * @returns {JSX.Element|null} A JSX element representing the slate.
 */

const OmegaSlate = ({ coreVideoReady, hasError, stream }) => {
  const FF = useFeatureFlagContext()['googlebot-slate'];

  if (coreVideoReady && !hasError) return null;

  const loadingSlate = {
    img: STREAM_TO_SLATE_MAP[stream] ?? NEWS_NOW_DEFAULT_SLATE,
    alt: 'Loading video. Please wait.',
  };

  const errorSlate = {
    img: 'https://media-cldnry.s-nbcnews.com/image/upload/q_auto:eco/f_auto/newscms/2024_02/3635347/error_code_0035.png',
    alt: 'Something went wrong! Please check your internet connection and try again later.',
  };

  const isBot = FF && (detect()?.type === 'bot' || detect()?.type === 'bot-device');

  const slate = (hasError && !isBot) ? errorSlate : loadingSlate;

  const slateStyles = classNames(
    styles.omegaVideoSlate,
    styles.omegaVideoSlateContainer,
  );

  return (
    <div className={slateStyles}>
      <img src={slate.img} alt={slate.alt} />
    </div>
  );
};

OmegaSlate.propTypes = {
  coreVideoReady: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  stream: PropTypes.string.isRequired,
};

export { OmegaSlate };
