import React from 'react';
import { ImageSlate } from 'components/TVE/Slate';
import { GEO_RESTRICTED_SLATE } from 'lib/playmaker';
import styles from './styles.module.scss';

export function GeoRestrictionSlate() {
  return (
      <div className={styles.slate}>
        <ImageSlate
          src={GEO_RESTRICTED_SLATE}
          alt="You are not authorized to access this content from outside of the US and its territories"
        />
      </div>
  );
}
