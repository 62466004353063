import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { RouteContext, useFeatureFlagContext } from 'lib/ContextTypes';

export const withViewportVisibility = (WrappedComponent, minHeight) => {
  const ComponentWithVisibility = (props) => {
    const { ref, inView } = useInView({
      initialInView: false,
      rootMargin: '200px 0px',
      threshold: 0,
      triggerOnce: true,
    });

    const { 'lazy-render-commerce-components': lazyRenderCommerceComponents } = useFeatureFlagContext();

    const route = React.useContext(RouteContext);
    const isAmp = route.path.indexOf('embedded-pkg') !== -1;

    return (
      <div ref={ref} style={{ minHeight }}>
        {
          (inView && lazyRenderCommerceComponents) || isAmp || !lazyRenderCommerceComponents
            // eslint-disable-next-line react/jsx-props-no-spreading
            ? <WrappedComponent {...props} />
            : null
        }
      </div>
    );
  };

  ComponentWithVisibility.propTypes = {
    minHeight: PropTypes.string,
  };

  ComponentWithVisibility.defaultProps = {
    minHeight: 'min-content',
  };

  // Set display name for better debugging in React DevTools
  ComponentWithVisibility.displayName = `withViewportVisibility(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return ComponentWithVisibility;
};
