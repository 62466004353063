import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RouteContext, BreakpointContext } from 'lib/ContextTypes';

import { useIsUIVisible } from 'components/VideoContainer/context/state/isUIVisible';
import { useIsReady } from 'components/VideoContainer/context/state/isReady';
import { useIsFullscreen } from 'components/VideoContainer/context/state/isFullscreen';

import pipIcon from 'assets/icons/picture-in-picture-icon.svg';


import { Backdrop } from './Backdrop';
import { Volume } from './Volume';

import styles from './styles.module.scss';
import { PlayPauseButton } from './PlayPauseButton/index';
import { FullScreenButton } from './FullScreenButton/index';
import { SubtitlesButton } from './SubtitlesButton/index';
import { LiveLabel } from './LiveLabel/index';

export const pagesThatWantToEnablePiP = [
  'msnbc.com/live',
  'nbcnews.com/now',
  'nbcnews.com/',
  'msnbc.com/',
  'telemundo.com/noticias',
  'telemundo.com/noticias/noticias-telemundo-ahora',
].map((s) => s.toLowerCase());

/* eslint-disable jsx-a11y/img-redundant-alt */

/**
 *
 * @param {object} params
 * @param {boolean} [params.isRail = false]
 * @returns
 */
export function VideoControlRack({ isRail = false }) {
  const isUIVisible = useIsUIVisible();
  const isReady = useIsReady();
  const isFullscreen = useIsFullscreen();

  const isSupportingPictureInPicture = typeof document !== 'undefined' && 'pictureInPictureEnabled' in document;

  const { isSorM } = React.useContext(BreakpointContext);
  const { domain, path } = React.useContext(RouteContext);

  const onNoticiasCover = domain === 'telemundo.com' && path === '/noticias';
  const enabledOnNoticiasCover = (onNoticiasCover && isSorM) || (onNoticiasCover && isFullscreen);

  if (!isReady) {
    return null;
  }

  const handleClick = async () => {
    const coreVideoContainer = document?.querySelector('#core-video');
    const iFrameDocument = coreVideoContainer?.querySelector('iframe')?.contentWindow?.document;
    const video = iFrameDocument?.body?.querySelector('video');

    if (iFrameDocument?.pictureInPictureElement) {
      await iFrameDocument?.exitPictureInPicture();
      return;
    }

    video?.requestPictureInPicture();
  };

  const pipButtonElement = <button id="pip-button" style={{ paddingLeft: '1.5rem' }} type="button" onClick={handleClick}><img style={{ minWidth: '25px' }} alt="picture-in-picture button" src={pipIcon} /></button>;

  const shouldEnablePiPOnCurrentPage = pagesThatWantToEnablePiP.includes(`${domain}${path}`);
  const shouldRenderPipButton = isSupportingPictureInPicture && shouldEnablePiPOnCurrentPage && !isFullscreen;

  return (
    <>
      <Backdrop />
      <div
        data-testid="rack"
        className={classNames(styles.center, styles.playPauseMobileContainer, {
          [styles.uiVisible]: isUIVisible,
        })}
      >
        <PlayPauseButton mobile />
      </div>
      <div
        className={classNames(styles.controlRack, {
          [styles.uiVisible]: isUIVisible,
        })}
      >
        <div className={classNames(styles.sections, styles.left)}>
          <Volume />
          {(!isRail || enabledOnNoticiasCover) && <LiveLabel />}
        </div>
        <div className={classNames(styles.sections, styles.center)}>
          <PlayPauseButton />
        </div>
        <div className={classNames(styles.sections, styles.right)}>
          {(!isRail || enabledOnNoticiasCover) && <SubtitlesButton />}
          { shouldRenderPipButton && pipButtonElement }
          <FullScreenButton />
        </div>
      </div>
    </>
  );
}

VideoControlRack.propTypes = {
  isRail: PropTypes.bool,
};

VideoControlRack.defaultProps = {
  isRail: false,
};
